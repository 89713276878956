import React from 'react';

import SkewBg from '@common/SkewBg';
import PageHeader from '@common/PageHeader';
import Flex from '@common/Flex';

import Quote from './Quote';
import Avatar from './Avatar';

import { AboutWrapper, AboutInfo } from './About.style';

const About = () => {
  return (
    <AboutWrapper id="about">
      <PageHeader>About Me</PageHeader>
      <SkewBg />
      <AboutInfo>
        <div>
          <Avatar src="hfest_avatar_2.jpg" />
        </div>
        <p>
          Hi, I'm Natan Maciel Cavalcanti, a self-taught passionate BackEnd developer from
          Brazil, currently working at IBM
          as a Application Developer. I've been building stuff and softwares since when
          I was in 6th standard, I've made countless side projects and I also
          posses magical powers of using spring to build delightful softwares and application programming interfaces.
          <br />
        </p>
      </AboutInfo>

      <Flex justify="space-between" className="quotes__wrapper">
        <Quote>
          <p>“Don't settle for good, be the best.“</p>
        </Quote>
        <Quote>
          <p>
            “Big goals attract big results.“
          </p>
        </Quote>
        <Quote>
          <p>
            “Be passionate about each achievement and always aim for each more.”
          </p>
        </Quote>
      </Flex>
    </AboutWrapper>
  );
};

export default About;
